<template>

    <div class="about">
      <span>
        2018年6月，我做了一件作品。我盡我所有能力搜集當時關於我本身的資訊，做成了一個時空膠囊。
      我搜集了自己的聲音，自己的身體，曾經跟別人聊天的內容，做了一百題給自己的問與答。
      有人說畢業後的人生將重新開始，這件作品成了對於我第一次人生即將結束的掛念。
      三年後的6月，我將我三年前的身體重新拿出來。不同的是，我將送走它。
      代替我被禁錮在公寓裡現在的肉身，他將分散在網路世界的各個地方。
      </span>
      <span>
        In June 2018, I made a piece of work, which I did all I could to collect information about myself,
        then I made a time capsule.
        I collected my voice, my body, the content of conversations I had with others, and 100 questions and answers for myself.Some people say that after graduation, life will start all over again.
        This piece of work became a capsule, and it became a reminder of my first life coming to an end.
        In June 2021, three years later, I brought out my body from 2018. The difference was that I'm going to send it away.
        In place of the body I have been imprisoned in my apartment, it would be scattered all over the Internet world.
      </span>
    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.about{
  position:absolute;
  box-sizing: border-box;
  left: 10%;
  top: 10%;
  width:80%;
  height:80%;
  padding:20px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  @media only screen and (max-width: 600px) {
    padding: 0;
}
  span {
    padding: 20px;
    margin: 10px;
    color: #ffffff;
    background: rgba($color: #000000, $alpha: 0.2);
    border: solid 1px white;
    border-radius: 5px;
    @media only screen and (max-width: 600px) {
    padding: 5px;
    font-size: 12px;
}
  }
}

</style>
