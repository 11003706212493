<template>
  <div>
  <div ref="container" class="container">
    <button class="back-button" @click="backToFront">《《《 BACK</button>
    <transition name="fade"><About v-if="showAbout" /></transition>
  </div>
</div>
</template>

<script>
import * as THREE from 'three';
import gsap from 'gsap';
import { load, dispatch } from '../utils/threeHelper';
import About from './About.vue';
export default {
  components: { About },
  name: 'Three',
  data () {
    return {
      renderer: undefined,
      raycaster: new THREE.Raycaster(),
      mouse: new THREE.Vector2(),
      scene: {},
      camera: {},
      events: {},
      width: 0,
      height: 0,
      time: 0,
      startTime: 0,
      prevTime: 0,
      activeLabel: '',
      buttonId: {
        button_left_eye: '39276',
        button_left_hand: '128153',
        button_left_ear: '128118',
        button_left_leg: '137234',
        button_mouth: '47889',
        button_nose: '47288',
        button_right_eye: '41629',
        button_right_hand: '128147',
        button_right_ear: '128138',
        button_right_leg: '137256'
      },
      showAbout: false
    };
  },
  mounted () {
    const dom = this.$refs.container;

    this.width = dom.clientWidth;
    this.height = dom.clientHeight;
    const loader = new THREE.FileLoader();
    loader.load('app.json', async (text) => {
      const { camera, scene, renderer, events } = await load(JSON.parse(text, this.events));
      this.camera = camera;
      this.scene = scene;
      this.renderer = renderer;
      this.events = events;

      dom.appendChild(this.renderer.domElement);

      await this.setSize();
      await this.play();
      await this.updateLabels();
      window.addEventListener('resize', this.setSize);
    });
  },
  computed: {
    isMobile() {
      let check = false;
      (function(a) {
        if (
          // eslint-disable-next-line no-useless-escape
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
            .test(a.substr(0, 4))) check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      if (this.width < 600) check = true;
      return check;
    }
  },
  destroyed () {
    this.stop();
  },
  methods: {

    setPixelRatio (pixelRatio) {
      this.renderer.setPixelRatio(pixelRatio);
    },

    setSize () {
      const dom = this.$refs.container;
      this.width = dom?.clientWidth;
      this.height = dom?.clientHeight;

      if (this.camera) {
        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix();
      }

      if (this.renderer) {
        this.renderer.setSize(this.width, this.height);
      }
    },

    clickButton () {
      // update the picking ray with the camera and mouse position
      this.raycaster.setFromCamera(this.mouse, this.camera);

      // calculate objects intersecting the picking ray
      const objects = this.scene.getObjectByName('main')
        .children.filter(item => item.name.startsWith('button')) || [];
      const button = this.raycaster.intersectObjects(objects)?.[0];
      if (button) {
        const labelName = button.object.name.replace('button', 'text');
        const label = this.scene.getObjectByName(labelName);
        if (label.material.opacity !== 0) {
          window.open(`https://www.hicetnunc.xyz/objkt/${this.buttonId[button.object.name]}`);
        }
      }
    },

    animate () {
      this.time = performance.now();

      try {
        dispatch(this.events.update, { time: this.time - this.startTime, delta: this.time - this.prevTime });
      } catch (e) {
        console.error((e.message || e), (e.stack || ''));
      }

      this.rotateBody();

      this.renderer.render(this.scene, this.camera);

      this.prevTime = this.time;
    },

    updateLabels () {
      const labels = this.scene.getObjectByName('main')
        .children.filter(item => item.name.startsWith('button'));
      for (const label of labels) {
        label.traverse(function (el) {
          if (el.material) {
            el.material.transparent = true;
            if (el.material.opacity > 0) { gsap.to(el.material, { opacity: 0, duration: 1 }); }
          }
        });
      }
    },

    rotateBody () {
      const body = this.scene.getObjectByName('body');
      // body.rotation.x += 0.01;
      body.rotation.z += 0.01;
      // body.rotation.z += 0.01;
    },

    cameraFocus () {
      // update the picking ray with the camera and mouse position
      this.raycaster.setFromCamera(this.mouse, this.camera);

      // calculate objects intersecting the picking ray
      const objects = this.scene.children.find(item => item.name === 'main')
        .children
        .filter(item => item.name !== 'body' && !item.name.startsWith('button')) || [];
      const intersects = this.raycaster.intersectObjects(objects) || [];
      for (let i = 0; i < intersects.length; i++) {
        const label = this.scene.getObjectByName('label_' + intersects[i].object.name);
        const vm = this;

        if (this.activeLabel !== label.name) {
          this.activeLabel = label.name;
          label.traverse(function (el) {
            vm.updateLabels();
            if (el.material) gsap.to(el.material, { opacity: 1, duration: 2 });
            if (el.material) gsap.from(el.position, { y: -5 });
          });
        }

        const objPos = intersects[i].point;
        gsap.to(this.camera.position, { ...objPos, z: objPos.z + (this.isMobile ? 30 : 15), duration: 2 });
        gsap.to(this.camera.rotation, { x: 0, y: 0, z: 0, duration: 2 });
        this.camera.lookAt(objPos);
      }
    },

    clickBody () {
      // update the picking ray with the camera and mouse position
      this.raycaster.setFromCamera(this.mouse, this.camera);

      // calculate objects intersecting the picking ray
      const objects = this.scene.getObjectByName('body').children;
      const intersects = this.raycaster.intersectObjects(objects) || [];
      if (intersects.length > 0) this.showAbout = true;
      else if (this.showAbout === true) { this.showAbout = false; }
    },

    play () {
      this.startTime = this.prevTime = performance.now();

      document.addEventListener('keydown', this.onKeyDown);
      document.addEventListener('keyup', this.onKeyUp);
      document.addEventListener('pointerdown', this.onPointerDown);
      document.addEventListener('pointerup', this.onPointerUp);
      document.addEventListener('pointermove', this.onPointerMove);

      dispatch(this.events.start);

      this.renderer.setAnimationLoop(this.animate);
    },

    stop () {
      document.removeEventListener('keydown', this.onKeyDown);
      document.removeEventListener('keyup', this.onKeyUp);
      document.removeEventListener('pointerdown', this.onPointerDown);
      document.removeEventListener('pointerup', this.onPointerUp);
      document.removeEventListener('pointermove', this.onPointerMove);

      dispatch(this.events.stop);

      this.renderer.setAnimationLoop(null);
    },

    render (time) {
      dispatch(this.events.update, { time: time * 1000, delta: 0 });

      this.renderer.render(this.scene, this.camera);
    },

    dispose () {
      this.renderer.dispose();

      this.camera = undefined;
      this.scene = undefined;
    },

    onKeyDown (event) {
      dispatch(this.events.keydown, event);
    },

    onKeyUp (event) {
      dispatch(this.events.keyup, event);
    },

    onPointerDown (event) {
      dispatch(this.events.pointerdown, event);
    },

    onPointerUp (event) {
      this.mouse.x = (event.clientX / this.width) * 2 - 1;
      this.mouse.y = -(event.clientY / this.height) * 2 + 1;
      this.cameraFocus();
      this.clickButton();
      this.clickBody();

      dispatch(this.events.pointerup, event);
    },

    onPointerMove (event) {
      dispatch(this.events.pointermove, event);
    },

    backToFront() {
      gsap.to(this.camera.position, { x: 0, y: 0, z: this.isMobile ? 50 : 35, duration: 2 });
      gsap.to(this.camera.rotation, { x: 0, y: 0, z: 0, duration: 2 });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  position:absolute;
  left: 0;
  top: 0;

  .back-button {
    position:absolute;
    left: 10px;
    top: 10px;
    border: none;
    background: transparent;
    box-shadow: none;
    color:white;
    font-size: 24px;
  }
}
</style>
